import "../App.css";
//import {Link} from "react-router-dom";
//import {useParams} from "react-router-dom";
import { getProgramList } from "./getProgramlist";
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Heading,
   } from '../ui';
 import { useNavigate } from "react-router-dom";
import { Banner } from "../ui/Banner";
import { storeHistory } from "../viewpodcast/navigation";
import { currentUserInfo } from "../user/currentUserInfo";
import { consoleMsg, debugMsg } from "../util/utils";
import { getCurrentUserInfo } from "../user/getCurrentUserInfo";


const DetailsSection = styled.div`
  flex: 8;
`;

export const ProgramList = () => {
  console.log("Home - produce programlist");

  const [isLoading, setIsLoading] = useState(true);
  const [programList, setProgramList] = useState(true);
  const navigate = useNavigate();

  //console.log ("XXX podcast page, loading:" ,isLoading);

  useEffect(() => {
    const loadProgramList = async () => {
      let userInfo = currentUserInfo;
      debugMsg("current user info", currentUserInfo)
      userInfo = await getCurrentUserInfo();
      
      const accessGroups = userInfo?.accessGroups;
      debugMsg("getting programs, access groups", accessGroups);
      if (accessGroups) {
        const result = await getProgramList(accessGroups);
        //console.log("received podcastlist: ", result)
        setProgramList(result);
        setIsLoading(false);
      } else {
        debugMsg("no access groups, no programs")
      }
    }

    loadProgramList();
  }, []);


  function ProgramItem ({program}) {
    const rowSelected = () => {
      console.log ("selected: ", program.key);
      navigate ("/"+ program.key);
    };

    return(
    <div>
      <div className="program-list-item" onClick={rowSelected} >
        <div className="program-title">
          <img width="100px" alt={program.name} src={program.imgUrl}/>
          {program.name}
        </div>
        <div className="program-description">{program.description}</div>
      </div>
    </div>
    
    );
  }

  storeHistory();

  // Display a loading message while the Firebase data is loading
  //console.log ("XXX loading:" ,isLoading);
  return (isLoading ? <Heading>Loading...</Heading> : 
    <DetailsSection>

      <Banner title="Programs Overview"/>

      <div className="program-list">
        {programList.map((program) => 
          (
            <ProgramItem key={program.id} program={program}></ProgramItem>
      
          )) }
       </div>
    </DetailsSection>
  )
}