import "../App.css";
//import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import { getPodcastList } from "./getPodcastlist";
import { getProgramInfo } from "./getProgramlist";
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Heading} from '../ui';
 import { useNavigate } from "react-router-dom";
import { Banner } from "../ui/Banner";
import { currentUserInfo } from "../user/currentUserInfo";
import { storeHistory } from "../viewpodcast/navigation";
import { debugMsg } from "../util/utils";
import { getCurrentUserInfo } from "../user/getCurrentUserInfo";

const DetailsSection = styled.div`
  flex: 8;
`;

export const PodcastList = () => {
  const {programKey} = useParams();
  debugMsg("Podcastlist for", programKey);

  const [isLoading, setIsLoading] = useState(true);
  const [podcastList, setPodcastList] = useState(true);
  const [programInfo, setProgramInfo] = useState("-");

  const [nameTag, setNameTag] = useState(currentUserInfo.firstName);

  const podcastId = useRef();
  const navigate = useNavigate();
  //console.log ("XXX podcast page, loading:" ,isLoading);

  useEffect(() => {
    const loadPodcastList = async (programKey) => {
    const result = await getPodcastList(programKey);
    //console.log("received podcastlist: ", result)
    setPodcastList(result);
    const programInfo = await getProgramInfo(programKey);
    setProgramInfo(programInfo);
    //console.log("XXX program:", programInfo);
    setIsLoading(false);

    //now, the user info will impact the display, so let's get that when it's not yet loaded and trigger a re-render
    const loadUserInfo = async () => {
        debugMsg ("Podcast list: Reading user info first time");
        const myUserInfo = await getCurrentUserInfo();
        debugMsg ("podcast list; user:", myUserInfo.firstName);
        setNameTag(currentUserInfo.firstName); // should trigger re-render name tag
      }
    if (currentUserInfo.firstName === "-") {
      loadUserInfo();
    }
  }

  loadPodcastList(programKey);
  }, []);

  const formatTime = (inSeconds) => {
    const minutes = `${Math.floor(inSeconds / 60)}`;
    const seconds = `${Math.floor(inSeconds % 60)+100}`.substring(1,3);
    return `${minutes}:${seconds}`
  }

  function Podcast ({podcast, programInfo}) {
    const rowSelected = () => {
      console.log ("selected: ", podcast.id);
      navigate ("/viewPodcast/"+ podcast.id);
    };
    const history = currentUserInfo.history;
    const duration = podcast.duration ? podcast.duration : 120; //when no duration avail, use something
    
    //determine listened status for display
    let podcastClass = "podcast-new";
    if (history[podcast.collection]) {
      if (history[podcast.collection][podcast.item]){
        if(history[podcast.collection][podcast.item] > (duration-60)){
          podcastClass = "podcast-listened";
        }
        else if(history[podcast.collection][podcast.item] > 30){
          podcastClass = "podcast-halfway";
        }
      }
    }

    return(
      <tbody>
        <tr className={podcastClass} onClick={rowSelected}>
          <td>{podcast.recordingDate ? podcast.recordingDate : "-"}</td>
          <td>{podcast.title? podcast.title : programInfo.name}</td>
          <td>{podcast.duration ? formatTime(podcast.duration): "-"}</td>
        </tr>
        <tr className={podcastClass} onClick={rowSelected}>
          <td colspan={3}>{podcast.description? podcast.description : ""} </td>
        </tr>
      </tbody>
    );
  }

  storeHistory();

  // Display a loading message while the Firebase data is loading
  //console.log ("XXX loading:" ,isLoading);
  return (isLoading ? <Heading>Loading...</Heading> : 
    <DetailsSection>
      <Banner title={programInfo.name} programInfo={programInfo}/>
       <table className="podcast-list">
        <tbody>
         {podcastList.map((podcast) => 
          (
            <Podcast key={podcast.id} podcast={podcast} programInfo={programInfo}></Podcast>
          )) }
        </tbody>
      </table>
    </DetailsSection>
  )
}
