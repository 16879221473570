import { useParams } from "react-router-dom";
import { ViewPodcastPage } from "./ViewPodcast";
import { getPodcastLink } from "./getPodcastLink";
import { Heading} from '../ui';
import { debugMsg } from "../util/utils";
import { useEffect, useState } from "react";


export const PreviewPodcast = () => {
  const {linkId} = useParams();

  const [podcastFound, setPodcastFound] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLink = async (linkId) => {
      const podcastId = await getPodcastLink(linkId);
      if (podcastId) {
        debugMsg("podcast found:", podcastId);
        setPodcastFound(podcastId);  
      } else {
        debugMsg("podcast not found");
      }
      setLoading(false);
    }
    loadLink(linkId);
  },[]);

  
  return (loading ? <p>loading...</p> :
          podcastFound ? <ViewPodcastPage podcastId={podcastFound} previewMode={true}/> :
          <Heading>Podcast not found</Heading>)
  
}
