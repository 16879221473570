import firebase from 'firebase/app';

export const getUserInfo = async userId => {
    const userInfoDoc = await firebase.firestore()
        .collection('users')
        .doc(userId)
        .get();

    const userInfo = userInfoDoc.data();

    if (!userInfo) return null;

    const userRightsDoc = await firebase.firestore()
    .collection('useradmin')
    .doc(userId)
    .get();

    if(!!userRightsDoc) {
      const userRights = userRightsDoc.data();
      if (userRights) {
        userInfo.accessGroups = userRights.accessGroups;
        userInfo.admin = userRights.admin;
        //debugMsg("access rights: ", userInfo.accessGroups);
      } else {
        userInfo.accessGroups = {general: "all"};
        userInfo.admin = false;
        //debugMsg("default access rights: ", userInfo.accessGroups);
      }
    } else {
      userInfo.accessGroups = {general: "all"};
      userInfo.admin = false;
      //debugMsg("default access rights: ", userInfo.accessGroups);
    }

    return {
        ...userInfo,
        id: userInfoDoc.id,
    };
}