import { SignOutButton } from "../auth";
import { Link } from "../ui";
import { currentUserInfo } from "../user/currentUserInfo";
import { getCurrentUserInfo } from "../user/getCurrentUserInfo";
import { useEffect, useState } from "react";
import { debugMsg } from "../util/utils";


export const Banner = ({title, programInfo}) => {

  const [nameTag, setNameTag] = useState(currentUserInfo.firstName==="-" ? "[Edit profile]"
         : currentUserInfo.firstName);

  const ProgramInfo = ({programInfo}) => {
    if (programInfo) {
      return <img style={{maxWidth:"200px",width: "100%"}} alt={programInfo.name} src={programInfo.imgUrl}/>
    }
    else return <img width="100px" alt={"Runway Podcasts"} src={"/images/runwaypodcasts.png"}/>;
  }

  // XXX not doing this right. need to re-render header when user info comes in
  const UserInfo = () => {
    useEffect(() => {
      const loadUserInfo = async () => {
          // debugMsg ("Banner; read user info first time");
          const myUserInfo = await getCurrentUserInfo();
          // debugMsg ("Banner; user:", myUserInfo.firstName);
          //debugMsg ("Banner; global user info:", currentUserInfo.firstName);
          setNameTag(currentUserInfo.firstName); // should trigger re-render name tag
        }
      if (currentUserInfo.firstName === "-") {
        loadUserInfo();
      }
    }, []);

    return <Link className="username" to="/edit-profile">{nameTag}</Link>
  }



  return <div>
    <nav>
      <Link to="/">[Home]</Link>
      <Link to="/about">[About]</Link>
      <UserInfo/>
      <SignOutButton>Sign out</SignOutButton>
    </nav>
    <h1>{title}</h1>
    <ProgramInfo programInfo={programInfo}/>
  </div>

}
