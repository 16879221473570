// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsKKrijcx6aRB2OAmPQq9Fj89Ul_409Z0",
  authDomain: "imposing-terra-284009.firebaseapp.com",
  projectId: "imposing-terra-284009",
  storageBucket: "imposing-terra-284009.appspot.com",
  messagingSenderId: "86904577416",
  appId: "1:86904577416:web:b902f38908dd93b185ae3d",
  measurementId: "G-REME26K7XL"
};

export default firebaseConfig;