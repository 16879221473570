// function to introduce delay, and see what's happening
// more for testing purposes

//DEBUG setting to turn on a lot of console messages
export const debug = true;
// export const debug = false;

export const AppVersionNumber = "2.0"; 

const formatTime = (inMs) => {
  const msDay = inMs;
  const ms = `${Math.floor(msDay % 1000)+1000}`.substring(1,5);
  const minutes = `${Math.floor(msDay / 1000 / 60) % 60 + 100}`.substring(1,3);
  const hours = `${Math.floor(msDay / 1000 / 3600) % 24}`;
  const seconds = `${Math.floor((msDay /1000 ) % 60)+100}`.substring(1,3);
  return `${hours}:${minutes}:${seconds}:${ms}`
}

export function delay(ms) {
    debugMsg("Delay!!! ", ms, " ms!");
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const debugMsg = (message, message2="", message3="", message4="", message5="", message6="") => {
  if(debug) {
    const time = formatTime(Date.now());
    console.log(`${time}:`, message, message2,message3, message4, message5, message6);
  }
}

export const consoleMsg = (message, message2="", message3="", message4="") => {
  const time = formatTime(Date.now());
  console.log(`${time}:`, message, message2,message3, message4);
}
