import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  App,
} from "./App";

// https://firebase.google.com/docs/web/setup#available-libraries
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'; // at least so we can see the user state
import 'firebase/analytics';
import firebaseConfig from "./config"
import { debugMsg } from "./util/utils";

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// debugMsg ("XXX Firebase App:",app);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
