import { podcastDetails } from "./ViewPodcast";
import { currentUserInfo } from "../user/currentUserInfo";
import { updateCurrentUserInfo } from "../user/updateCurrentUserInfo";
import { consoleMsg, debugMsg} from "../util/utils";

export const storeHistory = () => {
  // story in database - only need to store when something changed

  // avoid considering storing info when user info not loaded yet
  if (currentUserInfo.firstName !== "-") {
    // not sure how, but sometimes still history gets cleared. avoid this.
    if (currentUserInfo.history === {}){
      consoleMsg("XXX was trying to store empty history - don't");
    } else
    {
      // console.log("XXX store History");
      if (JSON.stringify(currentUserInfo.history) !== currentUserInfo.historyStored){
        const history = currentUserInfo.history;
        const changes = {
          history
        }
        updateCurrentUserInfo(changes);
        debugMsg("XXX updated history in user record:", currentUserInfo.history);
      }
      currentUserInfo.lastSync = Date.now();
      currentUserInfo.historyStored = JSON.stringify(currentUserInfo.history);
    }
  }
}


const updateHistory = (timeStamp) => {
  
  if(!currentUserInfo.history[podcastDetails.collection]){
    currentUserInfo.history[podcastDetails.collection]={}
  }
  currentUserInfo.history[podcastDetails.collection][podcastDetails.item] = timeStamp;
  // debugMsg ("XXX history:", currentUserInfo.history);

  //store when needed, 
  const lastSaveAgo = (Date.now()- currentUserInfo.lastSync) / 1000;
    //console.log("XXX last history storage:", lastSaveAgo);
    if (lastSaveAgo > 120){
      storeHistory();
    }
  }

export const whereWereWe = () => {
  if(currentUserInfo.history[podcastDetails.collection]){
    if(currentUserInfo.history[podcastDetails.collection][podcastDetails.item]){
      return currentUserInfo.history[podcastDetails.collection][podcastDetails.item];
    } else return 0;
  } else return 0;
}

const findRowForTime = (mediaTime,eventParams) => {
  let lastTimeFound = 0;
  let rowNr = 0; // first row to check will be row 1 (skip header row)
  while (lastTimeFound <= mediaTime+0.2){
    rowNr += 1;
    if (rowNr >= podcastDetails.transcript.length) { return rowNr-1}; //end of table: return the row that existed, which is then the last row
    lastTimeFound = podcastDetails.transcript[rowNr].startSec;
  }
  // now we found the row that starts AFTER the row we were looking for, so return -1
  return rowNr-1;
}

export const highlightRow = (rowNr, eventParams) => {
  const previousRowNr = eventParams.playStatus.highlightedRowNr;
  eventParams.playStatus.highlightedRowNr = rowNr;
  const row = eventParams.transcriptTableRef.current.rows[rowNr];
  const sample = podcastDetails.transcript[rowNr].text_ee.substring(0,20);
  debugMsg("highlighting row ", rowNr, "(was",previousRowNr,"):", sample, ".")

  // set previouw rownr to normal display
  if ((previousRowNr > -1) && (previousRowNr !== rowNr)) {
    const previousRow = row.parentNode.rows[previousRowNr];
    previousRow.removeAttribute("class");
  }
  row.setAttribute("class", "highlighted");
  //scroll to make sure the line is visible
  const rowOffset = row.offsetTop;
  
  // table can be scrolled by 1 levels up :table -> div 
  const scrollableDiv = eventParams.transcriptTableRef.current.parentElement;
  // if needed, the div to the target row's position
  let rect =  row.getBoundingClientRect();
  
  let visible = (rect.top >= 0 && rect.bottom <= scrollableDiv.clientHeight)
  if (!visible) {
    //console.log("scrolling, rect.top:", rect.top)
    scrollableDiv.scrollTop = rowOffset;
    }

  // //video elemement
  // if (videoElement) {

  //   let textOverlay = videoElement.parentElement.querySelector(".text-overlay");
  //   textToDisplay = row.getElementsByTagName("td")[2].innerHTML;
  //   //textToDisplay = transcript[rowNr].text_ee
  //   //console.log("update text overlay: ", textOverlay, " with ", textToDisplay)
  //   textOverlay.innerHTML = textToDisplay;

  //   textOverlay = videoElement.parentElement.querySelector(".text-translation");
  //   textToDisplay = row.getElementsByTagName("td")[3].innerHTML;
  //   //console.log("update translation overlay: ", textOverlay, " with ", textToDisplay)
  //   textOverlay.innerHTML = textToDisplay;
  // }
  
}
  

const endTimeForRow = (rowNr, eventParams) => {
  // end time for a row number is the beginning time of the next row
  if (rowNr + 1 >= podcastDetails.transcript.length) {
    console.log("last row reached.")
    return 100000; // when there is no next row, the end is eternity :-) 
    };
  return podcastDetails.transcript[rowNr+1].startSec;
}
  
export const showRowForTime = (mediaTime, eventParams) => {
    const rowNr = findRowForTime(mediaTime, eventParams);
    // console.log ("XXX found row nr", rowNr);
    highlightRow(rowNr, eventParams);
    eventParams.playStatus.playTimerEndtime = endTimeForRow(rowNr, eventParams);
}
  
   //play the recording from the starttime provided in seconds
const goToTime = (seconds,rowNr, eventParams, startPlay=true) => {
    const audioElement = eventParams.audioRef;
    audioElement.current.currentTime = seconds;
  
    const playbackRate = calcPlaybackSpeed(rowNr, eventParams);
    // debugMsg("XXX setting playbackspeed to", playbackRate);
    if (playbackRate){ audioElement.current.playbackRate = playbackRate;}
   
    if(startPlay) {
      audioElement.current.play();
    }
    //read the new end time from the start time of the next row
    const endTime = podcastDetails.transcript[rowNr+1].startSec;
    eventParams.playStatus.playTimerEndtime = endTime;
    debugMsg("XXX Start playing from :" , seconds , ", endtime:" , endTime);
    updateHistory(seconds);
}

const calcPlaybackSpeed = (rowNr, eventParams) => {
    const relativeSpeed = podcastDetails.transcript[rowNr].talkSpeed / podcastDetails.avgTalkSpeed;
    // do not change the playbackspeed when the rate is very low (unreliable, could be music etc.)
    //console.log ("XXX calcPlaybackspeed for row ",rowNr,": relativeSpeed=", relativeSpeed)
    //console.log (podcastDetails, podcastDetails.transcript[rowNr]);

    if (relativeSpeed >= 0.8)
    {
      let playbackRate = parseInt(eventParams.playbackSpeed.replace("*",""))/100;
      if (eventParams.playbackSpeed.endsWith("*")) {
        playbackRate = playbackRate /  Math.min(1.2,Math.max(0.8,(Math.sqrt(relativeSpeed)))); // when "*" dynamic setting, compensate speed for speed of talking
      }
      return playbackRate;
    } else {
      return null;
    }
  //console.log (`XXX speaker speed: ${Math.round(relativeSpeed * 100)}%, player: ${Math.round(playbackRate * 100)}%`);
}

export const settingsForTime = (mediaElement,eventParams) => {
  let rowNr = findRowForTime(mediaElement.currentTime, eventParams);

  const playbackRate = calcPlaybackSpeed(rowNr, eventParams);
  if (playbackRate){
    const difference = Math.abs(playbackRate - mediaElement.playbackRate);
    if (difference > 0.1) {
       mediaElement.playbackRate = playbackRate;
       debugMsg("changed playbackspeed to ", playbackRate);
      }
    // else {debugMsg("keep speed ", mediaElement.playbackRate, playbackRate, "because difference only ", difference)}
  } 
  return rowNr;
}


// time handling audio element
export const timeUpdateEvent = (event, eventParams ) => {
    const mediaElement = event.target;
    if (mediaElement.currentTime >= eventParams.playStatus.playTimerEndtime) 
    {
      // debugMsg (`XXX media timer went off at ${mediaElement.currentTime}, speed=${eventParams.playbackSpeed}, pause=`, eventParams.pauseSetting);
      updateHistory(mediaElement.currentTime);
      // find the row in the transcriptTable that is now playing (so that starts at this timeout)

      let rowNr = settingsForTime(mediaElement,eventParams);

      if (eventParams.pauseSetting.value !== 0) {
        mediaElement.pause();
  
        //now pause the playback for a sec to let the user read the previous transcript
        setTimeout (() => {
          mediaElement.currentTime = mediaElement.currentTime - 0.2; //go a bit back to not miss anything
          mediaElement.play();
        }, eventParams.pauseSetting.value)
  
      } else {
        highlightRow(rowNr, eventParams);
      }  
    
      // now set the end time to the start of the next row
      eventParams.playStatus.playTimerEndtime = endTimeForRow(rowNr,eventParams); 

      // debugMsg("XXX setting timer to start next line ", (rowNr + 1)," :" , eventParams.playStatus.playTimerEndtime);
    }
} 
  
export const playStarted = (event, eventParams ) => {
  debugMsg("XXX play started at ", event.target.currentTime);
  showRowForTime (event.target.currentTime, eventParams);
}

export const rowClicked = (event, eventParams) => {
  const row = event.target.parentElement;
  const rowNr = row.rowIndex;
  consoleMsg ("row ", rowNr, " clicked!");
  //console.log ("XXX podcast data", podcastDetails);
  const startTime = podcastDetails.transcript[rowNr].startSec;
  
  //console.log ("start time: ", startTime)
  // activate player from time determined
  goToTime(startTime,rowNr, eventParams);
  highlightRow(rowNr, eventParams); 
}

export const goToRow =  (rowNr, eventParams) => {
  consoleMsg ("go to row ", rowNr);
  const startTime = podcastDetails.transcript[rowNr].startSec;
  
  goToTime(startTime,rowNr, eventParams, false);
  highlightRow(rowNr, eventParams); 
} 