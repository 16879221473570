import { getCurrentUser } from '../auth';
import { getUserInfo } from './getUserInfo';
import { currentUserInfo } from './currentUserInfo';
import { debugMsg } from '../util/utils';

export const getCurrentUserInfo = async () => {
    const currentUser = getCurrentUser();
    //console.log("XXX Getting current user info, current user id:", currentUser.id);
    if (!currentUser) return null;
//    return await getUserInfo(currentUser.id);
    const info = await getUserInfo(currentUser.id);
    currentUserInfo.firstName = info.firstName;
    currentUserInfo.lastName = info.lastName;
    //deep copy of info history so it will live it's own life during the sessions
    // debugMsg("XXX history in user info:", info.history);
    if (info.history) {
      currentUserInfo.history = JSON.parse(JSON.stringify(info.history));
    } else (currentUserInfo.history = {});
    currentUserInfo.historyStored = JSON.stringify(currentUserInfo.history);
    currentUserInfo.lastSync = Date.now();
    if(info.admin) {currentUserInfo.admin = info.admin}
    if(info.accessGroups) {currentUserInfo.accessGroups = info.accessGroups}

    if (info.userPauseSetting) {currentUserInfo.userPauseSetting = info.userPauseSetting}
    if (info.userSpeedSetting) {currentUserInfo.userSpeedSetting = info.userSpeedSetting}

    // debugMsg("Read userinfo from database", info);
    return info;
}