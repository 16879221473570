import firebase from 'firebase/app';
import {debugMsg, delay} from '../util/utils';

// Wrapper function to get stuff from firebase
export const getPodcastLink = async (linkId) => {
  debugMsg ("Getting podcast from Link:", linkId);
  
  //hack to avoid 1000 queries when there is a mistake
  // await delay(100);
  
  const linkDoc = await firebase.firestore()
      .collection('podcastKeys')
      .doc(linkId)
      .get();

  const linkDetails = linkDoc.data();
  debugMsg("link details:",linkDetails);
  if (linkDetails) {
    const podcastId = linkDetails?.id;
    debugMsg("linking to podcast", podcastId);
    return podcastId;
  } else {
    return;
  }

}