import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './useAuth';

export const ProtectedRoute = ({children}) => {

  const { isLoading, user } = useAuth();
  const isAuthed = !!user;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthed) {
    console.log ("XXX user not yet signed in");
    return <Navigate to='/sign-in' />
  }

  //console.log ("XXX User", user , " logged in. produce page for ", children.type.name);
  return (
    <>{children}</>
  );
}