import firebase from 'firebase/app';
import {debugMsg, delay} from '../util/utils';

// Wrapper function to get stuff from firebase
export const getPodcastDetails = async (podcastId) => {
  // debugMsg ("Reading podcastDetails from database,", podcastId);
  debugMsg("Get Podcastdetails...");
  
  //hack to avoid 1000 queries when there is a mistake
  // await delay(100);
  
  // return [{id:'1',name:'hallo'},{id:'2',name:'doei'},{id:'3',name:'derde'}];
  
  const podcastListDoc = await firebase.firestore()
      .collection('podcastslist')
      .doc(podcastId)
      .get();

  //console.log ("postcast doc:", podcastListDoc);

  const podcastDetails = podcastListDoc.data();
  //console.log ("XXX postcast details: ", podcastDetails);

  // now also get the transcript
  const transcriptDoc = await firebase.firestore()
  .collection('podcasts')
  .doc(podcastDetails.transcriptId)
  .get();

  const transcriptData = transcriptDoc.data();

  //any meaningfule result found?
  if (!transcriptData.transcript[0].text_ee){
    console.log ("no valid transcript found");
    podcastDetails.transcript = null;
    return podcastDetails;
  }

  let totalDuration = 0;
  let totalSpeech = 0;
  //console.log ("XXX transcriptData:", transcriptData)
  const transcript = transcriptData.transcript.map((line) => {
    const startSec = parseInt(line.start.substring(3,5))*60 + parseInt(line.start.substring(6,8)) +
      parseFloat(line.start.substring(9,12)) / 1000;
    const endSec = parseInt(line.end.substring(3,5))*60 + parseInt(line.end.substring(6,8)) +
      parseFloat(line.end.substring(9,12)) / 1000;
    const duration = endSec - startSec;
    totalDuration += duration;
    const speakFlow = line.text_ee.length;
    const talkSpeed = Math.round(speakFlow / duration)
    totalSpeech += speakFlow;

    return {
      'id'        : line.id,
      'start'     : line.start,
      'end'       : line.end,
      'text_ee'   : line.text_ee,
      'text_en'   : line.text_en,
      'startSec'  : startSec,
      'endSec'    : endSec,
      'duration'  : duration,
      'talkSpeed' : talkSpeed
    };
  });

  //console.log ("XXX transcript:", transcript)

  //add the transcript to the struct 
  podcastDetails.transcript = transcript;
  podcastDetails.transcriptUpdated = transcriptData.updated;
  podcastDetails.totalSpeech = totalSpeech;
  podcastDetails.totalDuration = totalDuration;
  podcastDetails.avgTalkSpeed = Math.round(totalSpeech / totalDuration)
    
  if (!podcastDetails) return null;

  return podcastDetails;
}