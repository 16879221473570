import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    CenteredContentBox,
    HeadingSmall,
    TextInput,
    TextArea,
    UploadSingleFileButton,
} from '../ui';
import { getCurrentUserInfo } from './getCurrentUserInfo';
import { updateCurrentUserInfo } from './updateCurrentUserInfo';
import { pauseOptions, speedOptions } from '../viewpodcast/ViewPodcast';

const Form = styled.div`
    width: 100%;
    margin: 16px;
`;

const FieldsTable = styled.table`
    td {
        padding: 4px;
        width: 60%;
    }
`;

const FullWidthInput = styled(TextInput)`
    width: 100%;
`;

const FullWidthButton = styled(Button)`
    width: 100%;
`;

/*
    This page loads a user's current profile data (name, bio, etc.)
    and allows them to edit it. When the user clicks "save", the changes
    will be persisted to Firebase.
*/
export const EditProfilePage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profilePictureFile, setProfilePictureFile] = useState('');
    const [bio, setBio] = useState('');
    const [userPauseSetting, setUserPauseSetting] = useState('none');
    const [userSpeedSetting, setUserSpeedSetting] = useState('100');

    const navigate = useNavigate();

    useEffect(() => {
        const loadUserInfo = async () => {
            const userInfo = await getCurrentUserInfo();
            setFirstName(userInfo.firstName);
            setLastName(userInfo.lastName);
            setBio(userInfo.bio);
            if(userInfo.userPauseSetting) {
              setUserPauseSetting(userInfo.userPauseSetting)
            }
            if(userInfo.userSpeedSetting) {
              setUserSpeedSetting(userInfo.userSpeedSetting)
            }
            setIsLoading(false);
            console.log ("found user:", userInfo.firstName)
        }

        loadUserInfo();
    }, []);
    
    const handleFileSelect = file => {
        setProfilePictureFile(file);
    }

    const onSubmitChanges = async () => {
        const changes = {
            firstName,
            lastName,
            bio,
            userPauseSetting,
            userSpeedSetting
        }
        await updateCurrentUserInfo(changes);
        navigate('/');
    }

    return (
        <CenteredContentBox>
            <Form>
                <HeadingSmall>Edit User Profile</HeadingSmall>
                <FieldsTable>
                    <tbody>
                        <tr>
                            <td>First Name:</td>
                            <td>
                                <FullWidthInput
                                    disabled={isLoading}
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Last Name:</td>
                            <td>
                                <FullWidthInput
                                    disabled={isLoading}
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>Upload a Profile Picture:</td>
                            <td>
                                <UploadSingleFileButton
                                    disabled={isLoading}
                                    onFileUploaded={handleFileSelect}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Bio:</td>
                            <td>
                                <TextArea
                                    disabled={isLoading}
                                    rows='5'
                                    value={bio}
                                    style={{ width: '100%' }}
                                    onChange={e => setBio(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                          <td style={{background: 'green', color: 'white'}} colSpan={2}>User preferences</td>
                        </tr>
                        <tr>
                          <td>
                            Pause between sentences while playing podcast 
                          </td>
                          <td>
                             <select onChange={e => setUserPauseSetting(e.target.value)} value={userPauseSetting}>
                                {pauseOptions.map((option, index) => {
                                  return <option key={index}>
                                      {option.name}
                                    </option>
                                })}
                            </select>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Playback speed 
                          </td>
                          <td>
                             <select onChange={e => setUserSpeedSetting(e.target.value)} value={userSpeedSetting}>
                                {speedOptions.map((option, index) => {
                                  return <option key={index}>
                                      {option}
                                    </option>
                                })}
                            </select>
                          </td>
                        </tr>

                    </tbody>
                </FieldsTable>
                <FullWidthButton
                    disabled={isLoading}
                    onClick={onSubmitChanges}
                >Save Changes</FullWidthButton>
            </Form>
        </CenteredContentBox>
    )
}