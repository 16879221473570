import firebase from 'firebase/app';
import {debugMsg, delay} from '../util/utils';

// Wrapper function to get stuff from firebase
export const getProgramList = async (accessGroups) => {
    console.log ("getting programs");
    
    //hack to avoid 1000 queries
    await delay(200);
    const allPrograms = accessGroups.master ? true : false;

    
    // return [{id:'1',name:'hallo'},{id:'2',name:'doei'},{id:'3',name:'derde'}];
    
    const programListDoc = await firebase.firestore()
        .collection('programs')
        .where('transcribe', '==', true)
        .get();

    let unsortedProgramList = programListDoc.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
    }));

    if (!unsortedProgramList) return null;

    // filter for rights. should be server-side
    if (!allPrograms) {

      unsortedProgramList = unsortedProgramList.reduce((resultList, program) => {
        // console.log (program.name, program.accessGroup);
        const accessGroup = program.accessGroup;
        if (accessGroup in accessGroups) {
          resultList.push(program);          
        }
        return resultList;
      }, []);
    }


    const programList = unsortedProgramList.sort((a, b) => (a.newestEpisode < b.newestEpisode) ? 1 
              : (a.newestEpisode > b.newestEpisode) ? -1 : 0);
   
    return programList;
}

// first find based on key (new approach nov23), if that cannot be found, use the collection for the clause
export const getProgramInfo = async (programKey) => {
  
  debugMsg("read Program data")
  let programInfo;
  let programDoc = await firebase.firestore()
    .collection('programs')
    .doc(programKey)
    .get()
    .catch(() => {

    });
  // debugMsg("XXX loaded program Doc:", programDoc);


  programInfo = programDoc.data();
  if (programInfo) {
    programInfo.id = programKey;
    // debugMsg("XXX program data:", programInfo);
  } else {
    //doc not found by id - find based on query 
    programDoc = await firebase.firestore()
      .collection('programs')
      .where('key','==', programKey) 
      .get();

    programInfo = programDoc.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))[0];
  }

  if (!programInfo) return null;
  return programInfo;
}