import "./App.css";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";

import {
  ViewPodcast
} from "./viewpodcast/ViewPodcast";

import { PodcastList } from "./postcastlist/podcastlist";
import { ProgramList } from "./postcastlist/programlist";

import {
  CreateAccountPage,
  EmailConfirmationLanderPage,
  ProtectedRoute,
  SignInPage,
  useAuth,
} from './auth';
import { EditProfilePage } from './user';
import { LegalPage } from './legal'
import { AppVersionNumber, debugMsg } from "./util/utils";
import { PreviewPodcast } from "./viewpodcast/previewPodcast";

export function About() {
  return (
    <div>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
      </nav>
      <h1>Runway Podcasts!</h1>
      <h3>Build {AppVersionNumber}</h3>

      <p/>There's a lot to discover here. Let's get started.
      
      <p/>
      With this app, you can listen to Estonian podcasts, even when your Estonian is as bad as ours!

      <p/>
        There are a transcripts in Estonian, as well as the English translation. 

        <p/>
        You can adjust the playing speed to your liking and level, so you can follow it and pick up some proper Estonian on the go!
      

      <Outlet />
    </div>
  );
}

const onLeave = () => {
  console.log ("leaving podcast viewer");
}

export function App() {
  // debugMsg("app...")
  const {isLoading, user} = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path='/sign-in' 
          element={<SignInPage />}
        />
        <Route
          path='/legal'
          element={<LegalPage/>}
        />

        <Route
          path='/create-account'
          element={<CreateAccountPage/>}
        />

        <Route
          path='/edit-profile' 
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <EditProfilePage />
            </ProtectedRoute>
          } />

        <Route 
          path='/email-confirmation/success'
          element={<EmailConfirmationLanderPage success />}
        />
 
        <Route 
          path='/email-confirmation/failure'
          element={<EmailConfirmationLanderPage />}
        />

        <Route
          path='/programs' 
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <ProgramList /> 
            </ProtectedRoute>
          } />
        
        <Route
          path='/' 
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <ProgramList /> 
            </ProtectedRoute>
          } />

        <Route
          path='/:programKey' 
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <PodcastList /> 
            </ProtectedRoute>
          } />

        <Route 
          path="/about" 
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <About />
            </ProtectedRoute>
          }
        />

        <Route 
          path="/viewPodcast/:podcastId"
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <ViewPodcast />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewPodcast"
          element={
            <ProtectedRoute isAuthed={!!user} isLoading={isLoading}>
              <ViewPodcast />
            </ProtectedRoute>
          }
        />

        <Route 
          path="/previewPodcast/:linkId"
          element={
              <PreviewPodcast />
          }
        />


      </Routes>
    </BrowserRouter>
  );
}
