import firebase from 'firebase/app';
import {delay} from '../util/utils';

// Wrapper function to get stuff from firebase
export const getPodcastList = async (programKey) => {
    //console.log ("Getting list of podcasts for", programKey);
    
    //hack to avoid 1000 queries
    await delay(200);
    
    const podcastListDoc = await firebase.firestore()
        .collection('podcastslist')
        .where('collection', '==', programKey)
        .get();

    //console.log ("postcastList doc", podcastListDoc);

    const unsortedPodcastList = podcastListDoc.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
    }));

    if (!unsortedPodcastList) return null;

    const podcastList = unsortedPodcastList.sort((a, b) => (a.recordingDate < b.recordingDate) ? 1 
              : (a.recordingDate > b.recordingDate) ? -1 : 0);
    //console.log ("XXX list:", podcastList);
    //console.log ("XXX unsorted:", unsortedPodcastList);
  
    return podcastList;
}
