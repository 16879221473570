import "../App.css";
import { useParams } from "react-router-dom";
import { getPodcastDetails } from "./getPodcastDetails";
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import {
  Heading,
  Link,
} from '../ui';
import firebaseConfig from "../config"
import { timeUpdateEvent, playStarted, rowClicked, whereWereWe, showRowForTime, highlightRow, settingsForTime, goToRow } from "./navigation"; // navigation functions in separate module

import { getCurrentUserInfo } from "../user/getCurrentUserInfo";
import { getProgramInfo } from "../postcastlist/getProgramlist";
import { currentUserInfo } from "../user/currentUserInfo";
import { consoleMsg, debugMsg} from "../util/utils";

// import { translateLine } from "../admin/translate";
// import { updateTranscript } from "../admin/updateTranscript";

export const podcastDetails = {}

export const pauseOptions = [
  {"name" : "none", "value" : 0},
  {"name" : "short", "value" : 1000},
  {"name" : "mid", "value" : 2000},
  {"name" : "long", "value" : 5000}];

export const speedOptions = ["50","50*","65","65*","80", "80*", "90", "90*", "100","100*", "120","120*"];

const DetailsSection = styled.div`
  flex: 8;
  overflow-y: hidden;   
`;



const AudioElement = ({ innerRef, onTimeUpdate, ...rest }) =>
  <audio ref={innerRef} onTimeUpdate={onTimeUpdate} {...rest}></audio>


const playStatus = {
    playTimerEndtime  : 10000,
    highlightedRowNr  : -1,
    playStartTimeSet  : false
  }
  
export const ViewPodcast = () => {
  const {podcastId} = useParams();
  return <ViewPodcastPage podcastId={podcastId}/>
}


export function ViewPodcastPage({podcastId, previewMode}) {
  // debugMsg (`Podcast ${previewMode ? "PRE-" : ""}view, id=${podcastId}`);

  const [isLoading, setIsLoading] = useState(true);

  //defaults should be totally clean play - can add ways to emphasize the option to change speed
  // const [playbackSpeed, setPlaybackSpeed] = useState("100*");
  // const [pauseSetting, setPauseSetting] = useState({ "name": "mid", "value": 1000});

  const [playbackSpeed, setPlaybackSpeed] = useState("100");
  const [pauseSetting, setPauseSetting] = useState({ "name": "none", "value": 0});

  const storageRoot = "https://storage.googleapis.com/" + firebaseConfig.storageBucket + "/podcasts/";
  const [recordingFile, setRecordingFile] = useState("-"); 

  //const [highlightedRowNr, setHighlightedRowNr] = useState(-1);
  //const [playTimerEndtime, setPlayTimerEndtime] = useState(10000); //timer that triggers visual effects
  //const [playStartTimeSet, setPlayStartTimeSet] = useState(false);
    
  const [programInfo, setProgramInfo] = useState("-");
 
  const transcriptTableRef = useRef();
  const audioRef = useRef();  

  const [readerMode, setReaderMode] = useState("normal");
  const [userPlatform, setUserPlatform] = useState("other");
  // const [editMode, setEditMode] = useState(false);

  // edit mode params
  // const [editRowNr, setEditRowNr] = useState(-1);
  // const [lang1, setLang1] = useState();
  // const [lang2, setLang2] = useState();
  // const [lineChanged, setLineChanged] = useState(false);
  // const [origChanged, setOrigChanged] = useState(false);
  // const [transcriptChanged, setTranscriptChanged] = useState(false);
  

  
  //const [firstName, setFirstName] = useState('');
  // const [userPauseSetting, setUserPauseSetting] = useState('none');
  // const [userSpeedSetting, setUserSpeedSetting] = useState('100');

  //const eventParams = {transcriptTableRef, audioRef, playTimerEndtime, setPlayTimerEndtime, highlightedRowNr, setHighlightedRowNr, pauseSetting, playbackSpeed};
  const eventParams = {transcriptTableRef, audioRef, playStatus, pauseSetting, playbackSpeed};
  
  // wrapper for function that needs more parameters
  const localTimeUpdateEvent = (event) => {
  //  timeUpdateEvent(event, transcriptTableRef, playTimerEndtime, setPlayTimerEndtime, highlightedRowNr, setHighlightedRowNr);
    timeUpdateEvent(event, eventParams);
  }

  const localPlayStarted = (event) => {
  //  playStarted(event, transcriptTableRef, playTimerEndtime, setPlayTimerEndtime, highlightedRowNr, setHighlightedRowNr)
    playStarted(event, eventParams);
  }

  const localRowClicked = (event) => {
  rowClicked(event, eventParams);
  }


  // I need some user preferences here. So I lookup the user. There should be a smarter way to read the userprefs once and remember them
  useEffect(() => {
    const loadUserInfo = async () => {
        const userInfo = await getCurrentUserInfo();
        if (userInfo) {
          //setFirstName(userInfo.firstName);
          if(userInfo.userPauseSetting) {setPauseSetting(calcPauseValue(userInfo.userPauseSetting));}
          if(userInfo.userSpeedSetting) {setPlaybackSpeed(userInfo.userSpeedSetting);}
          // debugMsg (`read user info ${userInfo.firstName} settings: Speed=${userInfo.userSpeedSetting} Pause=${userInfo.userPauseSetting}`);
        } else {
          debugMsg("Anonymous preview");
        }
    }
    // when user info already loaded, use it
    if (currentUserInfo.firstName === "-") {loadUserInfo();} else {
      //setFirstName(currentUserInfo.firstName);
      if(currentUserInfo.userPauseSetting) {setPauseSetting(calcPauseValue(currentUserInfo.userPauseSetting));}
      if(currentUserInfo.userSpeedSetting) {setPlaybackSpeed(currentUserInfo.userSpeedSetting);}
      // debugMsg (`logged on user ${currentUserInfo.firstName} settings: Speed=${currentUserInfo.userSpeedSetting} 
          // Pause=${currentUserInfo.userPauseSetting}`);
    }
  },[]);
 
  //console.log ("XXX podcast transcript page, loading:" ,isLoading);
  
  useEffect(() => {
    const loadPodcastDetails = async (id) => {
      const result = await getPodcastDetails(id);
      // debugMsg("XXX received podcast details: ", result);
      podcastDetails.collection = result.collection;
      podcastDetails.id = result.id;
      podcastDetails.item = result.item;
      
      podcastDetails.recording = result.recording;
      podcastDetails.sourceMp3 = result.sourceMp3;
      setRecordingFile(podcastDetails.sourceMp3? podcastDetails.sourceMp3 : 
          storageRoot + result.recording);
      
      podcastDetails.recordingDate = result.recordingDate;
      podcastDetails.transcriptId = result.transcriptId;
      podcastDetails.transcript = result.transcript;
      podcastDetails.updated = result.updated;
      podcastDetails.video = result.video;
      podcastDetails.totalSpeech = result.totalSpeech;
      podcastDetails.totalDuration = result.totalDuration;
      podcastDetails.avgTalkSpeed = result.avgTalkSpeed;
      podcastDetails.image = result.image ;
      podcastDetails.link = result.link? result.link : "" ;

      const programInfo = await getProgramInfo(result.collection);
      setProgramInfo(programInfo);
      setIsLoading(false);
    }
    loadPodcastDetails(podcastId);

    // see if we can find out the user's platform (ios/android) so we can make more targeted links
    const userAgent = navigator.userAgent.toUpperCase();
    const platform = userAgent.indexOf("IPHONE") >= 0 ? "ios" : userAgent.indexOf("ANDROID") >= 0 ? "android" : "other"
    // debugMsg("XXX platform:", platform);
    setUserPlatform(platform);

    playStatus.playStartTimeSet = false; // to trigger player start time

  }, [podcastId]);
//}, [podcastId, podcastDetails.transcriptId]);

  const calcSpeedValue = (speedSetting) => {
    return parseInt(speedSetting.endsWith("*") ? speedSetting.substring(0,speedSetting.length -1) : speedSetting)
  }
  
  const selectSpeed = (event) => {
    const newSpeed = event.target.value;
    //const newSpeedValue = parseInt(newSpeed.endsWith("*") ? newSpeed.substring(0,newSpeed.length -1) : newSpeed)
    const newSpeedValue = calcSpeedValue(newSpeed);
    console.log (`new speed selected: ${newSpeed}, value=${newSpeedValue}.`);
    audioRef.current.playbackRate = (newSpeedValue/100);
    setPlaybackSpeed(newSpeed);
  }

  const calcPauseValue = (pauseSetting) => {
    return  {"name" : pauseSetting, "value" : pauseOptions.find(item => item.name === pauseSetting).value};
  }

  function selectPause (event) {
    const newPauseSetting = event.target.value;
    console.log ("set pause setting:", newPauseSetting)
    setPauseSetting(calcPauseValue(newPauseSetting));
  }

  const audioStarted = (e) => {
    // debugMsg("XXX canplay event! time set=", playStatus.playStartTimeSet);
    if (!playStatus.playStartTimeSet) {
      const startTime = whereWereWe();
      // debugMsg("XXX Audio ready, start time setting to ", startTime);
      e.target.currentTime = startTime;
      const rowNr = settingsForTime(e.target, eventParams);
      highlightRow (rowNr, eventParams);
      playStatus.playStartTimeSet = true; //avoid running again
    }
  } 
  
  const pictureClick = () => {
    //toggle readermode, 2 for now, but can be expanded
    let newReaderMode = "normal";
    if(readerMode==="normal"){
      newReaderMode = "large";
    } else {
      newReaderMode ="normal";
    }
    setReaderMode(newReaderMode);
    setPauseSetting(calcPauseValue("none"));
    consoleMsg("picture click. new reader mode:", newReaderMode);
  }

  // const pictureDoubleClick = () => {
  //   //admin only: edit mode
  //   if(currentUserInfo.admin === true){
  //     let newEditMode = false;
  //     if(editMode){
  //       newEditMode = false;
  //     } else {
  //       newEditMode = true;
  //     }
  //     setEditMode(newEditMode);
  //     consoleMsg("picture doubleclick. edit mode:", newEditMode);
  //   }
  // }

  // const playLine = (e) => {
  //   goToRow(editRowNr, eventParams);
  //   audioRef.current.play();
  // }

  // const selectEditRow = (e) => {
  //   const rowNr = e.target.parentElement.rowIndex
  //   setEditRowNr(rowNr);
  //   debugMsg("selected line for editing:", rowNr)
  //   setLang1(podcastDetails.transcript[rowNr].text_ee);
  //   setLang2(podcastDetails.transcript[rowNr].text_en);
  //   setLineChanged(false);
  //   setOrigChanged(false);
  // }


  // const saveChanges = async () => {
  //   consoleMsg("saving changes to database");
  //   await updateTranscript();
  //   alert("updates saved.");
  //   setTranscriptChanged(false);
  // }

  // const submitChanges = async () => {
  //   debugMsg("submit changes line",editRowNr,":", lang1, lang2);
  //   podcastDetails.transcript[editRowNr].text_ee = lang1;
  //   podcastDetails.transcript[editRowNr].text_en = lang2;
  //   podcastDetails.transcript[editRowNr].modified = true;
  //   setOrigChanged(false);
  //   setLineChanged(false);
  //   setTranscriptChanged(true);    
  //   // const translation = await translateLine(lang1);
  //   // debugMsg("translation:", translation);
  // }
  // const updateLine = async (updatedText = lang1) => {
  //   debugMsg("update translation");
  //   const translation = await (translateLine(updatedText));
  //   setLang2(translation);
  //   setOrigChanged(false);
  //   setLineChanged(true);
  //   goToRow(editRowNr,eventParams);
  // }

  // const lang1Changed = async (e) => {
  //   debugMsg("lang 1 updated...");
  //   const oldLang1 = lang1;
  //   const newLang1 = e.target.value;
  //   setOrigChanged(true);
  //   setLang1(newLang1);
  //   if (oldLang1 !== newLang1) {
  //     debugMsg("lang1 update also changed.")
  //     updateLine(newLang1);
  //   }
  // }

  // const SaveButton = () => {
  //   return(
  //     <button style={{backgroundColor: lineChanged? "red" : "lightgray"}} onClick={submitChanges}>Save</button>
  //   )
  // }
  // const EditTranscriptLine = ({line,index}) => {
  //   if (index===editRowNr) {
  //     return  (
  //       <tr bgcolor ={index % 2 === 1 ? "lightyellow" : "white" }> 
  //         <td hidden={true}>{line.startSec}</td>
  //         <td className="timestamp">{line.start.substring(3,5)}
  //           <div className="seconds">{line.start.substring(6,8)}</div>
  //           <button onClick={playLine}>Play</button>
  //           <SaveButton/>
  //         </td>   
  //         <td className="language1edit">
  //             <textarea rows={6} type="text" name={"lang1Input"} defaultValue={lang1} 
  //               style={{width: '100%', fontSize: "x-large"}} onBlur={lang1Changed} />
  //             <button style={{backgroundColor: origChanged? "red" : "lightgray"}} onClick={updateLine}>Update</button>
  //             <SaveButton/>
  //        </td>
  //         <td className="language2edit">
  //           <textarea rows={6} type="text" name={"lang2Input"} defaultValue={lang2} 
  //             style={{width: '100%', fontSize: "x-large"}} onBlur={e => setLang2(e.target.value)} />
  //             <SaveButton/>
  //       </td>
  //       </tr>
  //       )
  //   } else {
  //     const color = line.modified? "red" : "black";
  //     return  (
  //       <tr onClick={selectEditRow} bgcolor ={index % 2 === 1 ? "lightyellow" : "white" }> 
  //         <td hidden={true}>{line.startSec}</td>
  //         <td className="timestamp">{line.start.substring(3,5)}
  //         <div className="seconds">{line.start.substring(6,8)}</div></td>   
  //         <td className="language1" style={{color: color}}>{line.text_ee}</td>
  //         <td className="language2" style={{color: color}}>{line.text_en}</td>
  //       </tr>
  //       )
  
  //   }
  // }

  // const SaveChanges = () => {
  //   if (editMode) {
  //     return (
  //       <button onClick={saveChanges} style={{backgroundColor: transcriptChanged ? "red" : "lightgray"}} >SAVE CHANGES</button> 
  //      );
  //   } else {
  //     return "";
  //   }
  // }


  const PodcastTranscript = () => {
    // if (editMode){
    //   return podcastDetails.transcript.map((line, index) => (
    //    <EditTranscriptLine key={index} line={line} index={index} />
    //   ))
    // } else {
      if (readerMode==="normal"){  
        return podcastDetails.transcript.map((line, index) => (
          <tr key={index} onClick={localRowClicked} bgcolor ={index % 2 === 1 ? "lightyellow" : "white" }> 
            <td hidden={true}>{line.startSec}</td>
            <td className="timestamp">{line.start.substring(3,5)}
            <div className="seconds">{line.start.substring(6,8)}</div></td>   
            <td className="language1">{line.text_ee}
            {index % 20 === 4 ? // spam link to app regularly
              <div style={{color: 'red', textAlign: "right", verticalAlign:"center", fontWeight: "bold"}}>
              Get the Podcast Viewer App:
               </div>
             : ""
            }
            </td>
            <td className="language2">
              {line.text_en}
 
              {index % 20 === 4 ? // spam link to app regularly
                <a href={userPlatform === "ios" ? "https://apps.apple.com/us/app/runway-podcasts/id6466842780" : 
                      userPlatform === "android" ? "https://play.google.com/store/apps/details?id=com.runwayconcepts.runwaypodcasts" : 
                      "https://runwayconcepts.eu/#download"}>
                  <div>
                      <img align="left" height="100px" alt="Runway Podcasts" src="/images/runwaypodcasts.png"
                      />
                  </div>
                </a>
                : ""
              }

            </td>
 
          </tr>
        ))
      } else {
        return podcastDetails.transcript.map((line, index) => (
          <tr key={index} onClick={localRowClicked} bgcolor ={index % 2 === 1 ? "lightyellow" : "white" }> 
            <td hidden={true}>{line.startSec}</td>
            <td className="timestamp">{line.start.substring(3,5)}
            <div className="seconds">{line.start.substring(6,8)}</div></td>   
            <td className="large-transcript">
              {line.text_en}
            </td>
          </tr>
          ))
      } 
    // }
  }

  return (isLoading ? <Heading>Loading...</Heading> : 
    <DetailsSection >
      <div className="header-div">

        <table className="just-layout">
          <tbody>

          <tr >
            <td width="50%">

              <table className="just-layout">
                <tbody>
                  <tr>
                    <td>
                        <AudioElement innerRef={audioRef} onTimeUpdate={localTimeUpdateEvent} 
                          onPlay={localPlayStarted} id="mp3" alt="-" type="audio/mpeg" 
                        src={recordingFile} controls 
                        onCanPlay = {audioStarted}
                      />
                    </td>
                    <td>
                    {/* <SaveChanges/> */}
                    </td>

                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <Link className="podcast-title" to={"/"+podcastDetails.collection}>
                          {
                          //podcastDetails.collection
                          programInfo.name
                          }
                      </Link> 
                      <div className="podcast-title">
                          {podcastDetails.item}
                      </div> 
                    </td>
                    <td>

                      {previewMode ? "" : <nav> <Link to="/">Home </Link> </nav>}
                      
                      {/* </td>
                    <td> */}
                      <select onChange={selectSpeed} value={playbackSpeed}>
                        {speedOptions.map((option, index) => {
                          return <option key={index}>
                              {option}
                            </option>
                        })}
                      </select>
                    {/* </td>
                    <td> */}
                      <select onChange={selectPause} value={pauseSetting.name}>
                        {pauseOptions.map((option, index) => {
                          return <option key={index}>
                              {option.name}
                            </option>
                        })}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td align="right">
              <a href={podcastDetails.link ? podcastDetails.link : ""}>

                  <img height="100px" alt={programInfo.name} src={podcastDetails.image ? podcastDetails.image: programInfo.imgUrl}
                  // onDoubleClick={pictureDoubleClick}
                  // onClick={pictureClick}
                  />
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div className="scrollable-div">
        <table ref={transcriptTableRef}>
          <tbody>
            {!podcastDetails.transcript ?<tr><td>No podcast transcript yet!</td></tr> :
              <PodcastTranscript/>
            }
          </tbody>
        </table>
      </div>
    </DetailsSection>
  );
  }
  
